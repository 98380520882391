<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="d-flex justify-content-center flex-column text-20px px-20px">
    <div class="d-flex-center">
      <b-img
        :src="require('@/assets/icons/train-logo.svg')"
        style="height: 70px; margin-bottom: 4px;"
      />
    </div>
    <div
      class="text-uppercase text-center fw-800 mb-50"
      contenteditable
    >
      Thẻ lên tàu hỏa/boarding pass
    </div>

    <div class="text-center font-italic text-success font-small-4">
      <div
        contenteditable
        class="color-theme"
      >
        Đường sắt Bắc-Nam vinh dự được Lonely Planet bình chọn là tuyến đường đẹp nhất, đáng trải nghiệm nhất thế giới <br>
        The North-South railway route is proudly selected by Lonely Planet as the World’s most incredible and worth experiencing train journey
      </div>
    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
  props: {
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
