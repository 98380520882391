var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.config.showPrice ? _c('div', {
    staticClass: "d-flex flex-column gap-8px mt-50"
  }, [_vm.config.showPriceDetail ? _c('div', {
    staticClass: "d-flex-center flex-column gap-8px"
  }, [_c('div', {
    staticClass: "align-self-baseline fw-700 color-theme text-20px",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.ticketPrice', _vm.config.locale)) + " ")]), _vm._l(_vm.trips, function (trip, tripIndex) {
    var _trip$tripInfos$0$dep, _trip$tripInfos, _trip$tripInfos$0$arr, _trip$tripInfos2;
    return _c('div', {
      key: tripIndex,
      staticClass: "w-100"
    }, [_c('div', {
      staticClass: "text-dark fw-800 d-flex-between text-18px mb-50"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s((_trip$tripInfos$0$dep = trip === null || trip === void 0 ? void 0 : (_trip$tripInfos = trip.tripInfos) === null || _trip$tripInfos === void 0 ? void 0 : _trip$tripInfos[0].departureName) !== null && _trip$tripInfos$0$dep !== void 0 ? _trip$tripInfos$0$dep : trip.departure) + " - " + _vm._s((_trip$tripInfos$0$arr = trip === null || trip === void 0 ? void 0 : (_trip$tripInfos2 = trip.tripInfos) === null || _trip$tripInfos2 === void 0 ? void 0 : _trip$tripInfos2[0].arrivalName) !== null && _trip$tripInfos$0$arr !== void 0 ? _trip$tripInfos$0$arr : trip.arrival) + " ")])]), _c('div', {
      staticClass: "section-table",
      class: [{
        'border-theme': _vm.config.showBackgroundColor
      }]
    }, [_c('b-table', {
      staticClass: "mb-0",
      attrs: {
        "centered": "",
        "responsive": "",
        "borderless": "",
        "thead-class": "text-body align-middle text-nowrap text-right border-0",
        "tbody-class": "text-body align-middle text-nowrap text-right border-0",
        "fields": _vm.tableColumnsPaxFareInfo,
        "items": _vm.getPricePax(trip.tripInfos)
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnsPaxFareInfo, function (column, indexColumn) {
        return {
          key: "head(".concat(column.key, ")"),
          fn: function fn() {
            return [_c('span', {
              key: indexColumn,
              staticClass: "fw-700 text-14px",
              attrs: {
                "contenteditable": ""
              }
            }, [_vm._v(" " + _vm._s(_vm.$t("reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.".concat(column.key), _vm.config.locale)) + " ")])];
          },
          proxy: true
        };
      }), {
        key: "cell(passenger)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('span', {
            attrs: {
              "contenteditable": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("ticket.paxTypeCode".concat(data.item.paxName), _vm.config.locale)) + " x" + _vm._s(data.item.quantity) + " ")])])];
        }
      }, {
        key: "cell(netPrice)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.netPrice),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, data.item.paxType, 'netPrice');
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(tax)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder"
          }, [_c('IAmInputMoney', {
            attrs: {
              "value-money": Math.floor(data.item.tax),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, data.item.paxType, 'tax');
              }
            }
          })], 1)];
        }
      }, {
        key: "cell(total)",
        fn: function fn(data) {
          return [_c('div', {
            staticClass: "text-right font-weight-bolder color-theme"
          }, [_c('IAmInputMoney', {
            attrs: {
              "customClass": "fw-600",
              "value-money": Math.floor(data.item.total / data.item.quantity),
              "placeholder": "",
              "disable-class": true,
              "can-input-negative": true,
              "on-blur": function onBlur(text) {
                return _vm.handleChangePax(text, data.item.paxType, 'total');
              }
            }
          })], 1)];
        }
      }], null, true)
    }), _vm.showTotalPerTrip ? _c('div', {
      staticClass: "text-right px-1 py-50 fw-700"
    }, [_c('span', {
      attrs: {
        "contenteditable": ""
      }
    }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.total', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
      attrs: {
        "value-money": trip.tripInfos.reduce(function (total, it) {
          return total + it.totalPriceTicket;
        }, 0),
        "placeholder": "",
        "custom-class": "fw-700 color-theme",
        "disable-class": true,
        "can-input-negative": true,
        "on-blur": function onBlur(text) {
          return _vm.handleChangeBookingTotal(text);
        }
      }
    })], 1) : _vm._e()], 1)]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-column gap-2 px-1 py-75 w-100",
    class: 'rounded-8px bg-theme',
    style: "".concat(_vm.config.showBackgroundColor ? "background-color: ".concat(_vm.currentBgColor, " !important") : '')
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('span', {
    staticClass: "fw-700",
    attrs: {
      "contenteditable": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('ticket.priceTable.totalOfPrice', _vm.config.locale)) + ": ")]), _c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.sumAll,
      "placeholder": "",
      "custom-class": "text-22px fw-700 color-theme",
      "disable-class": true,
      "can-input-negative": true,
      "on-blur": function onBlur(text) {
        return _vm.handleChangeSumAll(text);
      }
    }
  })], 1)])]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }