<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="section-note"
    :class="[
      { 'border-theme': config.showBackgroundColor},
    ]"
  >
    <div style="flex: 1; position: relative;">
      <div v-if="config.locale === 'vi'">
        <quill-editor
          v-model="config.note"
          :options="editorOption"
        />
      </div>
      <div v-else-if="config.locale === 'en'">
        <quill-editor
          v-model="config.noteEn"
          :options="editorOption"
        />
      </div>
      <b-button
        v-if="isConfig && config.note"
        v-b-tooltip.hover.v-danger.window
        style="position: absolute; right: -20px; top: 0;"
        title="Đặt lại ghi chú về mặc định"
        variant="flat-danger"
        class="p-1 rounded-circle"
        @click="handleResetNote"
      >
        <feather-icon
          icon="RotateCwIcon"
          size="20"
        />
      </b-button>
    </div>

    <div
      v-show="config.showPayment"
      class="d-flex-center flex-column gap-4px"
      style="margin-left: 20px;"
    >
      <div class="font-weight-bolder color-theme text-nowrap">
        {{ $t('ticket.paymentInfo', config.locale) }}
      </div>
      <div class="border">
        <b-img
          :src="qrCodeAvatar"
          style="width: 100px;"
        />
      </div>
      <div
        v-if="defaultBankAccount"
        style="text-align: center;"
        class="font-weight-bolder"
      >
        <div>{{ defaultBankAccount.bankAccountName }}</div>
        <div>{{ defaultBankAccount.bankAccountNo }}</div>
        <div class="d-flex-center justify-content-start">
          <BImg
            :src="bankAvatar"
            width="70"
          />
          <div>{{ resolveBankCode(defaultBankAccount.bankCode).bankName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BImg } from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BImg,
    BButton,
    quillEditor,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    editorOption: {
      type: Object,
      default: () => {},
    },
    isConfig: {
      type: Boolean,
      default: false,
    },
    qrCodeAvatar: {
      type: String,
      default: '',
    },
    bankAvatar: {
      type: String,
      default: '',
    },
    defaultBankAccount: {
      type: [Object, null],
      default: null,
    },
    resolveBankCode: {
      type: Function,
      default: () => {},
    },
    handleResetNote: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
