<template>
  <div
    v-if="config.showPrice"
    class="d-flex flex-column gap-8px mt-50"
  >
    <div
      v-if="config.showPriceDetail"
      class="d-flex-center flex-column gap-8px"
    >
      <div
        class="align-self-baseline fw-700 color-theme text-20px"
        contenteditable
      >
        {{ $t('ticket.ticketPrice', config.locale) }}
      </div>

      <!-- SECTION GIÁ TỪNG PAX -->
      <div
        v-for="(trip, tripIndex) in trips"
        :key="tripIndex"
        class="w-100"
      >
        <div class="text-dark fw-800 d-flex-between text-18px mb-50">
          <span contenteditable>
            {{ trip?.tripInfos?.[0].departureName ?? trip.departure }} - {{ trip?.tripInfos?.[0].arrivalName ?? trip.arrival }}
          </span>
        </div>

        <div
          class="section-table"
          :class="[{ 'border-theme': config.showBackgroundColor}]"
        >
          <b-table
            centered
            responsive
            borderless
            class="mb-0"
            thead-class="text-body align-middle text-nowrap text-right border-0"
            tbody-class="text-body align-middle text-nowrap text-right border-0"
            :fields="tableColumnsPaxFareInfo"
            :items="getPricePax(trip.tripInfos)"
          >
            <template
              v-for="(column, indexColumn) of tableColumnsPaxFareInfo"
              #[`head(${column.key})`]
            >
              <span
                :key="indexColumn"
                contenteditable
                class="fw-700 text-14px"
              >
                {{ $t(`reservation.sellingPriceDetails.tableColumnsPaxFareInfoTicket.${column.key}`, config.locale) }}
              </span>
            </template>
            <template #cell(passenger)="data">
              <div class="text-right font-weight-bolder">
                <span contenteditable>
                  {{ $t(`ticket.paxTypeCode${data.item.paxName}`, config.locale) }}
                  x{{ data.item.quantity }}
                </span>
              </div>
            </template>
            <template #cell(netPrice)="data">
              <div class="text-right font-weight-bolder">
                <IAmInputMoney
                  :value-money="Math.floor( data.item.netPrice)"
                  placeholder=""
                  :disable-class="true"
                  :can-input-negative="true"
                  :on-blur="(text) => handleChangePax(text, data.item.paxType, 'netPrice')"
                />
              </div>
            </template>
            <template #cell(tax)="data">
              <div class="text-right font-weight-bolder">
                <IAmInputMoney
                  :value-money="Math.floor(data.item.tax)"
                  placeholder=""
                  :disable-class="true"
                  :can-input-negative="true"
                  :on-blur="(text) => handleChangePax(text, data.item.paxType, 'tax')"
                />
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-right font-weight-bolder color-theme">
                <IAmInputMoney
                  customClass="fw-600"
                  :value-money="Math.floor(data.item.total / data.item.quantity)"
                  placeholder=""
                  :disable-class="true"
                  :can-input-negative="true"
                  :on-blur="(text) => handleChangePax(text, data.item.paxType, 'total')"
                />
              </div>
            </template>
          </b-table>

          <!-- ANCHOR - Tổng 1 bảng -->
          <div
            v-if="showTotalPerTrip"
            class="text-right px-1 py-50 fw-700"
          >
            <span contenteditable>
              {{ $t('ticket.priceTable.total', config.locale) }}:
            </span>
            <IAmInputMoney
              :value-money="trip.tripInfos.reduce((total, it) => total + it.totalPriceTicket ,0)"
              placeholder=""
              custom-class="fw-700 color-theme"
              :disable-class="true"
              :can-input-negative="true"
              :on-blur="(text) => handleChangeBookingTotal(text)"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- ANCHOR - Tổng cuối cùng -->
    <div
      class="d-flex flex-column gap-2 px-1 py-75 w-100"
      :class="'rounded-8px bg-theme'"
      :style="`${config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''}`"
    >
      <div class="d-flex align-items-center justify-content-end">
        <span
          contenteditable
          class="fw-700"
        >
          {{ $t('ticket.priceTable.totalOfPrice', config.locale) }}:
        </span>
        <IAmInputMoney
          :value-money="sumAll"
          placeholder=""
          :custom-class="`text-22px fw-700 color-theme`"
          :disable-class="true"
          :can-input-negative="true"
          :on-blur="(text) => handleChangeSumAll(text)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import { computed, toRefs } from '@vue/composition-api'
import groupBy from 'lodash/groupBy'

import IAmInputMoney from '@/components/IAmInputMoney.vue'

export default {
  components: {
    IAmInputMoney,
    BTable,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    ticketData: {
      type: Object,
      default: () => ({}),
    },
    currentBgColor: {
      type: [String, null],
      default: null,
    },
    passengerTargetList: {
      type: Array,
      default: () => [],
    },
    trips: {
      type: Array,
      default: () => [],
    },
    sumAll: {
      type: Number,
      default: 0,
    },
    handleChangePax: {
      type: Function,
      default: () => {},
    },
    handleChangeSumAll: {
      type: Function,
      default: () => {},
    },
    handleChangeBookingTotal: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const configTicket = toRefs(props).config
    const currentBgColor = toRefs(props).currentBgColor
    const showTotalPerTrip = computed(() => props.trips?.length > 1)

    const tableColumnsPaxFareInfo = computed(() => {
      const arr = ['passenger', 'netPrice', 'tax', 'total']
      return (configTicket.value?.showPriceNetFare ? arr : arr.filter(item => ['passenger', 'total'].includes(item)))
        .map(item => ({
          key: item,
          label: item,
          thStyle: `background-color: ${configTicket.value.showBackgroundColor ? currentBgColor.value : 'white'} !important`,
        }))
    })

    function getPricePax(tickets) {
      const result = []
      const groupPax = groupBy(tickets, ticket => `${ticket.passengerTargetCode}-${ticket.totalPrice}`)
      for (const key in groupPax) {
        if (Object.prototype.hasOwnProperty.call(groupPax, key)) {
          const ticketList = groupPax[key]
          const discount = ticketList?.[0]?.feeService - (ticketList?.[0]?.fee + ticketList?.[0]?.parentFee)
          result.push({
            paxName: key.split('-')[0],
            quantity: ticketList.length,
            netPrice: ticketList?.[0]?.totalPrice || 0,
            tax: discount > 0 ? discount : 0,
            total: ticketList.reduce((total, it) => total + it.totalPriceTicket, 0),
          })
        }
      }

      return result
    }

    return {
      tableColumnsPaxFareInfo,
      getPricePax,
      showTotalPerTrip,
    }
  },
}
</script>
