<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="d-flex flex-column gap-2">
    <div
      v-for="trip of trips"
      :key="`${trip.departure}-${trip.arrival}`"
      class="flex-1"
    >
      <!-- ANCHOR - Trip Name -->
      <div class="d-flex align-items-center font-medium-5 fw-800 my-25">
        <IAmIcon
          icon="trainAndRail"
          class="text-secondary mr-1"
          size="55"
        />
        {{ trip.tripInfos?.[0].departureName ?? trip.departure }} - {{ trip.tripInfos?.[0].arrivalName ?? trip.arrival }}
      </div>

      <!-- ANCHOR - Data ticket -->
      <div
        class="section-table train-pax-table-template-columns"
        :class="[
          { 'border-theme': config.showBackgroundColor},
        ]"
      >
        <!-- ANCHOR - START label -->
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="`
            ${config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''}
          `"
          contenteditable
        >
          {{ $t('ticket.paxTable.no', config.locale) }}
        </div>
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable
        >
          {{ $t('ticket.paxTable.passenger', config.locale) }}
        </div>
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable
        >
          {{ $t('ticket.paxTable.trainName', config.locale) }}
        </div>
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable
        >
          {{ $t('ticket.paxTable.trainTicketNumber', config.locale) }}
        </div>
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable
        >
          {{ $t('ticket.paxTable.seatName', config.locale) }}
        </div>
        <div
          class="fw-700 d-flex align-items-center p-4px-16px"
          :style="config.showBackgroundColor ? `background-color: ${currentBgColor} !important` : ''"
          contenteditable
        >
          {{ $t('ticket.paxTable.travelTime', config.locale) }}
        </div>
        <!-- ANCHOR - END label -->

        <!-- ANCHOR - START ROW DATA -->
        <template v-for="(ticket, ticketIndex) in trip.tripInfos">
          <!-- ANCHOR - Item: STT -->
          <div
            :key="`no-${trip.departure}-${ticketIndex}`"
            class="text-18px p-4px-16px d-flex align-items-center w-100 border-t"
          >
            <div contenteditable>
              {{ String(ticketIndex + 1).padStart(2, "0") }}
            </div>
          </div>

          <!-- ANCHOR -  Item: Hành Khách -->
          <div
            :key="`pax-${trip.departure}-${ticketIndex}`"
            class="p-4px-16px d-flex flex-column w-100 border-t"
          >
            <div
              contenteditable
              class="w-100"
            >
              <span class="text-18px fw-600">
                ({{ $t(`ticket.paxTypeCode${ticket.passengerTargetCode}`, config.locale) }})
              </span>

              <span class="fw-700 text-20px">
                {{ ticket.fullName }}
              </span>
            </div>

            <div
              contenteditable
              class="text-18px text-secondary w-100"
            >
              Giấy tờ {{ ticket.identifyNumber || '-' }}
            </div>
          </div>

          <!-- ANCHOR - Item: Tàu -->
          <div
            :key="`trainCode-${trip.departure}-${ticketIndex}`"
            class="text-20px p-4px-16px d-flex flex-column justify-content-center w-100 fw-800 border-t"
          >
            <div
              v-if="ticket.trainCode"
              contenteditable
              style="width: 100%;"
            >
              <span class="color-theme">{{ ticket.trainCode }}</span>
            </div>
            <div
              v-else
              contenteditable
              class="color-theme"
              style="width: 100%;"
            />
          </div>

          <!-- ANCHOR - Item: Mã vé -->
          <div
            :key="`ticketId-${trip.departure}-${ticketIndex}`"
            class="text-20px p-4px-16px d-flex flex-column justify-content-center w-100 fw-800 border-t"
          >
            <div
              v-if="!isNil(ticket.ticketId)"
              contenteditable
              style="width: 100%;"
            >
              <span class="color-theme">{{ ticket.ticketId }}</span>
            </div>
            <div
              v-else
              contenteditable
              class="color-theme"
              style="width: 100%;"
            />
          </div>

          <!-- ANCHOR - Item: Chỗ -->
          <div
            :key="`seatLocation-${trip.departure}-${ticketIndex}`"
            class="text-16px p-4px-16px d-flex flex-column justify-content-center w-100 border-t"
          >
            <div
              contenteditable
              style="width: 100%;"
            >
              <span class="fw-800">Toa {{ ticket.coachName }} - chỗ {{ ticket.seatNumber }}</span>
            </div>
            <div
              contenteditable
              style="width: 100%;"
            >
              <span>{{ ticket.seatTypeName }}</span>
            </div>
            <!-- <div
              v-if="!ticket.ticketId"
              contenteditable
              style="width: 100%;"
            /> -->
          </div>

          <!-- ANCHOR - Item: Thời gian -->
          <div
            :key="`time-${trip.departure}-${ticketIndex}`"
            class="text-16px p-4px-16px d-flex flex-column justify-content-center w-100 border-t"
          >
            <div
              contenteditable
              style="width: 100%;"
            >
              <span class="fw-800">{{ ticket.departureHour }} {{ convertISODateTime(ticket.departureTime).date }} -</span>
            </div>
            <div
              contenteditable
              style="width: 100%;"
            >
              <span>{{ ticket.arrivalHour }} {{ convertISODateTime(ticket.arrivalTime).date }}</span>
            </div>
            <!-- <div
              v-if="!ticket.ticketId"
              contenteditable
              style="width: 100%;"
            /> -->
          </div>
        </template>
        <!-- ANCHOR - END ROW DATA -->
      </div>
    </div>
  </div>
</template>
<script>
// import { BButton } from 'bootstrap-vue'
// import { computed } from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { convertISODateTime } from '@/@core/utils/filter'

export default {
  // components: {
  //   BButton,
  // },
  props: {
    frontendI18n: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    trips: {
      type: Array,
      default: () => [],
    },
    currentBgColor: {
      type: String,
      default: '',
    },
    passengerTargetList: {
      type: Array,
      default: () => [],
    },
    addPax: {
      type: Function,
      default: () => {},
    },
    removePax: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    function getNamePaxType(code) {
      const data = props.passengerTargetList?.find(p => p?.code === code)
      if (data && data?.code === 6) return 'Người lớn nước ngoài'
      if (data && data?.code === 7) return 'Trẻ em nước ngoài'
      return data?.name || ''
    }

    // function getNamePaxType(code) {
    //   const data = getListPassengerTarget.value?.find(p => p?.code === code)
    //   if (data && data?.code === 6) return 'Người lớn nước ngoài'
    //   if (data && data?.code === 7) return 'Trẻ em nước ngoài'
    //   return data?.name || ''
    // }

    return {
      isNil,
      isEmpty,
      convertISODateTime,

      getNamePaxType,
    }
  },
}
</script>
